import React from 'react';
import CommonMain from "./CommonMain";

const HomeAdmin = () => {
	return (
		<CommonMain>
			home
		</CommonMain>
	);
};

export default HomeAdmin;