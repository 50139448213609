import React from 'react'
import "./MaterialBuild.scss"
import Header from '../layouts/header/Header'
import Footer from '../layouts/footer/Footer'
import ButtonFor from '../../components/Button/ButtonFor/ButtonFor'
import Material from '../../components/Material/Material'

function MaterialBuild() {
  return (
    <div className='material_build'>
      <Header />
      <ButtonFor text="VẬT LIÊU XÂY DỰNG" />

      <div className='material_build_ele'>
        <h3>Vật liệu xây dựng cơ bản</h3>
        <Material />
      </div>

      <div className='material_build_ele'>
        <h3>Vật liệu xây dựng kết cấu</h3>
        <Material />
      </div>

      <div className='material_build_ele'>
        <h3>Vật liệu xây dựng hoàn thiện</h3>
        <Material />
      </div>
      <Footer />
    </div>
  )
}

export default MaterialBuild
