import React from 'react'
import "./Material.scss"
import MaterialCard from './MaterialCard/MaterialCard'

function Material() {
  const data = [
    {
      id: 1,
      name: 'Xi măng bền Sun Phát',
      supplier: "Công ty Xi măng Nghi Sơn"
    },
    {
      id: 2,
      name: 'Xi măng CHINFON PCB40',
      supplier: "Công ty Xi măng Nghi Sơn"
    },
    {
      id: 3,
      name: 'Sắt thép Vinsteel',
      supplier: "Vật liệu xây dựng Huy Thanh Phong"
    },
    {
      id: 4,
      name: 'Cát bê tông',
      supplier: "Công ty Xi măng Nghi Sơn"
    },
    {
      id: 5,
      name: 'Gạch ống nung hà thành GN04',
      supplier: "Gạch HàThành"
    },
    {
      id: 6,
      name: 'Thép ống hàn đen',
      supplier: "Công ty Xi măng Nghi Sơn"
    },
  ]

  return (
    <div className='material'>
      {data?.map((item, index) => (
        <MaterialCard item={item}/>
      ))}
    </div>
  )
}

export default Material
