export const menuItems = [
  {
    title: 'Trang chủ',
    url: '/',
  },
  {
    title: 'Thiết kế',
    url: '/design',
  },
  {
    title: 'Xây dựng - Sửa chữa',
    url: '/supplier',
  },
  {
    title: 'Nội thất',
    url: '/furnitures',
  },
  {
    title: 'Vật liệu xây dựng',
    url: '/material-build'
  },
  {
    title: 'Diễn đàn',
    url: '/forum',
  },
]