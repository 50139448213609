import React from 'react'
import HeaderTop from './headerTop/HeaderTop'
import HeaderBody from './headerBody/HeaderBody'

function Header() {
  return (
    <div className='header'>
      {/* <HeaderTop/> */}
      <HeaderBody/>
    </div>
  )
}

export default Header