import React from 'react'
import "./DesignContent.scss"

function DesignContent() {
  return (
    <div className='design_content'>
      <div className='design_content_child'>
        <label>Phong cách</label>
        <span>Tân cổ điển</span>
      </div>
      <div className='design_content_child'>
        <label>Nhà cung cấp</label>
        <span>Nội Thất Trúc Linh - Công Ty CP Đầu Tư Trúc Linh</span>
      </div>
      <div className='design_content_child'>
        <label>Khu vực xây dựng</label>
        <span>320m2</span>
      </div>
      <div className='design_content_child'>
        <label>Vị trí</label>
        <span>Ninh bình</span>
      </div>
      <div className='design_content_child'>
        <label>Công năng bố trí</label>
        <span>03 Phòng ngủ chính , 1ngủ giúp việc, 1phòng trang điểm/phòng vệ sinh cho khách, 3WC</span>
      </div>
      <div className='design_content_child'>
        <label>Thời điển triển khai</label>
        <span>04/11/2022</span>
      </div>
      <div className='design_content_child'>
        <label>Diện tích đất</label>
        <span>Rộng 9M x Dài 17M</span>
      </div>
    </div>
  )
}

export default DesignContent
