import React from 'react'

function Contact() {
  return (
    <div className='contact'>
      
    </div>
  )
}

export default Contact
